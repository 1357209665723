














import {
  defineComponent,
  PropType,
  ref,
  computed,
  toRefs,
  watch,
} from '@vue/composition-api'

import InputCheckbox from '@/components/atoms/InputCheckbox.vue'
import OutputConditionRowLayout from '@/components/organisms/h/layout/OutputConditionRowLayout.vue'
import {
  checkboxOption,
  emitOutputConditionEach,
  outputCondUseInfo,
} from '@/types/entities'
import { OUTPUT_CAUSAL_COND_ITEM
} from '@/utils/constant'

export default defineComponent({
  components: {
    OutputConditionRowLayout,
    InputCheckbox,
  },
  props: {
    outputCondUseInfo: {
      type: Object as PropType<outputCondUseInfo>,
      default: () => ({
        outputCondType: 'CausalItem',
        displayItems: [],
        defaultSelected: [],
        disabledOptions: [],
      }),
      deep: true,
    },
  },
  setup(props, context) {
    const options = ref<checkboxOption[]>([
      {
        text: '全商品',
        value: OUTPUT_CAUSAL_COND_ITEM.all,
        disabled: false,
      },
      {
        text: '小分類',
        value: OUTPUT_CAUSAL_COND_ITEM.shobunrui,
        disabled: false,
      },
      {
        text: 'クラス',
        value: OUTPUT_CAUSAL_COND_ITEM.class,
        disabled: false,
      },
      {
        text: 'カテゴリ',
        value: OUTPUT_CAUSAL_COND_ITEM.category,
        disabled: false,
      },
      {
        text: '単品',
        value: OUTPUT_CAUSAL_COND_ITEM.tanpin,
        disabled: false,
      },
    ])

    const useOptions = computed(() => {
      // 使用する項目だけ表示
      const info = props.outputCondUseInfo
      let ret = options.value.filter((elem, index, self) => {
        return info?.displayItems.includes(elem.value)
      })
      // disabledの適用
      ret = ret.map((elem) => {
        let ret = elem
        if (info.disabledItems.includes(elem.value)) {
          ret.disabled = true
        }
        return ret
      })
      return ret
    })

    const selected = ref<string[]>(props.outputCondUseInfo.defaultSelected)

    /*********************************
     * 以下は出力条件変更時の処理
     *********************************/
    const changeOutputCondition = (val: string[]) => {
      selected.value = val
      const params: emitOutputConditionEach = {
        outputCondType: 'CausalItem',
        selected: val,
      }
      context.emit('change-output-condition', params)
    }

    return { useOptions, changeOutputCondition }
  },
})
