









import { defineComponent, PropType, ref, watch } from '@vue/composition-api'

import { checkboxOption } from '@/types/entities'

export default defineComponent({
  components: {},
  props: {
    options: {
      type: Array as PropType<checkboxOption[]>,
      default: () => [],
    },
    defaultSelected: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props, context) {
    const selected = ref<string[]>(props.defaultSelected)

    watch(selected, (newVal) => {
      // console.log('watch selected', { newVal })
      context.emit('change-selected', newVal)
    })

    return { selected }
  },
})
