














import {
  defineComponent,
  PropType,
  ref,
  computed,
  toRefs,
  watch,
} from '@vue/composition-api'

import InputCheckbox from '@/components/atoms/InputCheckbox.vue'
import OutputConditionRowLayout from '@/components/organisms/h/layout/OutputConditionRowLayout.vue'
import {
  checkboxOption,
  emitOutputConditionEach,
  outputCondUseInfo,
} from '@/types/entities'
import { OUTPUT_COND_STORE } from '@/utils/constant'

export default defineComponent({
  components: {
    OutputConditionRowLayout,
    InputCheckbox,
  },
  props: {
    outputCondUseInfo: {
      type: Object as PropType<outputCondUseInfo>,
      default: () => ({
        outputCondType: 'Store',
        displayItems: [],
        defaultSelected: [],
        disabledOptions: [],
        isTanaPattern: false,
      }),
      deep: true,
    },
  },
  setup(props, context) {
    const options = ref<checkboxOption[]>([
      {
        text: '全店',
        value: OUTPUT_COND_STORE.all,
        disabled: false,
      },
      {
        text: 'エリア',
        value: OUTPUT_COND_STORE.area,
        disabled: false,
      },
      {
        text: '棚パターン',
        value: OUTPUT_COND_STORE.tana,
        disabled: false,
      },
      {
        text: '単店舗',
        value: OUTPUT_COND_STORE.tanten,
        disabled: false,
      },
    ])

    const useOptions = computed(() => {
      // 棚パターン・エリアの出しわけ
      const info = props.outputCondUseInfo
      if (info?.isTanaPattern) {
        delete options.value[1]
      } else {
        delete options.value[2]
      }
      // 使用する項目だけ表示
      let ret = options.value.filter((elem, index, self) => {
        return info?.displayItems.includes(elem.value)
      })
      // disabledの適用
      ret = ret.map((elem) => {
        let ret = elem
        if (info.disabledItems.includes(elem.value)) {
          ret.disabled = true
        }
        return ret
      })
      return ret
    })

    const selected = ref<string[]>([''])

    /*********************************
     * 以下は出力条件変更時の処理
     *********************************/
    const changeOutputCondition = (val: string[]) => {
      selected.value = val
      const params: emitOutputConditionEach = {
        outputCondType: 'Store',
        selected: val,
      }
      context.emit('change-output-condition', params)
    }

    return { useOptions, changeOutputCondition }
  },
})
