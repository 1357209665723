















import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {},
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(_, context) {
    return {}
  },
})
