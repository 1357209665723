import { Where, WhereOp } from '@/types/generated/graphql'

/**
 * where 情報から指定キーの値を取得
 * @param where
 * @param key
 * @returns
 */
export function getWhereValue(wheres: Where[], key: string): string[] {
  for (const w of wheres) {
    const array = w.field
    for (let i = 0; i < array.length; i++) {
      if (array[i] === key && w.value) {
        return Array.from(new Set(w.value.map((i) => (i ? i : ''))))
      }
    }
  }
  return []
}
/**
 * 指定キーに値をセット
 * @param where
 * @param key
 * @param value
 * @returns
 */
export function setWhereValue<T>(
  wheres: Where[],
  key: string | string[],
  value: any | any[] | null | undefined,
  opts?: { ope: string }
) {
  for (let j = 0; j < wheres.length; j++) {
    const fieldArr = wheres[j].field
    console.log()
    const ope = opts && opts.ope ? opts.ope : ''
    for (let i = 0; i < fieldArr.length; i++) {
      if (
        (fieldArr[i] === key ||
          JSON.stringify(fieldArr) == JSON.stringify(key)) &&
        (ope ? ope == wheres[j].op : true)
      ) {
        // value が未指定 null or 空文字 を含む場合は条件自体から除去
        // false, 0 は条件として必要なためそのまま
        if (
          value === undefined ||
          value === null ||
          (typeof value === 'string' && value === '') ||
          (Array.isArray(value) && (value.length === 0 || value.includes('')))
        ) {
          wheres.splice(j, 1)
          return wheres
        }
        if (Array.isArray(value)) {
          wheres[j].value = value
        } else {
          wheres[j].value = [value.toString()]
        }
      }
    }
  }
  return wheres
}
export function setWhereValues<T>(
  wheres: Where[],
  entries: {
    key: string
    value: any | any[] | null | undefined
    opts?: { ope: string }
  }[]
): Where[] {
  entries.forEach((i) => {
    setWhereValue(wheres, i.key, i.value, i.opts)
  })
  return wheres
}
