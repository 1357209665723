























import { defineComponent, PropType, ref } from '@vue/composition-api'

import OutputConditionLayout from '@/components/organisms/h/layout/OutputConditionLayout.vue'
import CausalItemOutput from '@/components/organisms/h/outputCond/CausalItemOutput.vue'
import DeleteOutput from '@/components/organisms/h/outputCond/DeleteOutput.vue'
import ItemOutput from '@/components/organisms/h/outputCond/ItemOutput.vue'
import StoreOutput from '@/components/organisms/h/outputCond/StoreOutput.vue'
import {
  outputConditionGroupOutputCondition,
  outputCondUseInfoList,
  outputCondType,
  emitOutputConditionEach,
} from '@/types/entities'
import { OUTPUT_EXTRACT_TARGET } from '@/utils/constant'

export default defineComponent({
  components: {
    OutputConditionLayout,
    DeleteOutput,
    StoreOutput,
    ItemOutput,
    CausalItemOutput,
  },
  props: {
    useList: {
      type: Array as PropType<outputCondUseInfoList>,
      default: () => [],
    },
    enableExtractTarget: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    radioDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    extractTargetSelectVal: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props, context) {
    const usecomponent = (use: outputCondType) => {
      const componentName = {
        Store: 'StoreOutput',
        Item: 'ItemOutput',
        Delete: 'DeleteOutput',
        CausalItem: 'CausalItemOutput',
      }
      return componentName[use]
    }
    // 初期値を設定
    const extractTarget = ref<string[]>([props.extractTargetSelectVal])

    const changeExtractTarget = (selected: string[]) => {
      extractTarget.value = selected
      _emitChangeOutputCondition()
    }

    // 初期値を設定
    let storeOutput = ref<string[]>([''])
    let itemOutput = ref<string[]>([''])
    let delOutput = ref<string[]>([''])
    for (const use in props.useList) {
      switch (props.useList[use].outputCondType) {
        case 'Store':
          storeOutput.value = props.useList[use].defaultSelected
          break
        case 'Item':
          itemOutput.value = props.useList[use].defaultSelected
          break
        case 'CausalItem':
          itemOutput.value = props.useList[use].defaultSelected
          break
        case 'Delete':
          delOutput.value = props.useList[use].defaultSelected
          break
      }
    }

    // いずれかの出力条件変更時の処理
    const changeOutputCondition = (params: emitOutputConditionEach) => {
      switch (params.outputCondType) {
        case 'Store':
          storeOutput.value = params.selected
          break
        case 'Item':
          itemOutput.value = params.selected
          break
        case 'CausalItem':
          itemOutput.value = params.selected
          break
        case 'Delete':
          delOutput.value = params.selected
          break
        default:
          break
      }

      _emitChangeOutputCondition()
    }

    const _emitChangeOutputCondition = () => {
      const cond: outputConditionGroupOutputCondition = {
        extractTarget: extractTarget.value,
        storeOutput: storeOutput.value,
        itemOutput: itemOutput.value,
        delOutput: delOutput.value,
      }
      context.emit('change-output-condition', cond)
    }

    return {
      usecomponent,
      changeOutputCondition,
      changeExtractTarget,
    }
  },
})
