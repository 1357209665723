















































import { defineComponent, PropType, ref } from '@vue/composition-api'

import { OUTPUT_EXTRACT_TARGET } from '@/utils/constant'

export default defineComponent({
  components: {},
  props: {
    enableExtractTarget: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    extractTargetSelectVal: {
      type: String as PropType<string>,
      default: OUTPUT_EXTRACT_TARGET.specify,
    },
    radioDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, context) {
    const allVal = ref<string>(OUTPUT_EXTRACT_TARGET.all)
    const specifyVal = ref<string>(OUTPUT_EXTRACT_TARGET.specify)
    const selected =
      props.extractTargetSelectVal || ref<string>(OUTPUT_EXTRACT_TARGET.specify)
    const changeExtractTarget = (selected: string) => {
      // console.log('changeExtractSetting', { selected })
      const emitVal =
        selected === OUTPUT_EXTRACT_TARGET.specify ? [selected] : []
      context.emit('change-extract-target', emitVal)
    }
    return { allVal, specifyVal, selected, changeExtractTarget }
  },
})
